<script setup>
/**
 * SinglePageTitle
 * ------------------------------
 * Description:
 * A Vue component for displaying a title with an optional icon and action button.
 *
 * Usage:
 * <SinglePageTitle
 *    :title="'Page Title'"
 *    :icon="'icon-name'"
 *    :actionAvailable="true"
 *    @action="handleAction"
 * />
 *
 * Props:
 * - title: The title text to display.
 * - icon: The name of the icon to display alongside the title.
 * - actionAvailable: A boolean indicating whether an action button is available. Default is false.
 *
 * Events:
 * - action: Emitted when the action button is clicked.
 */

import { defineProps, defineEmits } from 'vue'

import { BaseImage } from '@/shared/ui/Images'
import { BaseButton } from '@/shared/ui/Buttons'
import { BaseLoader } from '@/shared/ui/Loaders'

const emits = defineEmits(['action'])
const props = defineProps({
    title: {
        type: String
    },
    icon: {
        type: String
    },
    actionAvailable: {
        type: Boolean,
        default: false
    },
    loaded: {
        type: Boolean,
        default: true
    }
})

const action = () => {
    emits('action')
}
</script>

<template>
    <div class="single-page-title">
        <template v-if="!loaded">
            <BaseLoader />
        </template>
        <template v-else>
            <h2 class="mob-tablet-h2">{{ title }}</h2>
        </template>
        <BaseButton v-if="actionAvailable && loaded" :icon="'plus.svg'" :width="40" :height="40" :border-radius="'4px'" @click="action" />
    </div>
</template>

<style scoped>
.single-page-title {
    display: grid;
    grid-template-columns: 1fr max-content;
    place-items: center stretch;
    gap: 13px;
}

.loader-wrapper {
    justify-content: flex-start;
}

h2 {
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
