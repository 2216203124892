import {route as mainRoute} from './main'
import {route as leadsRoute} from './leads'
import {route as brandsRoute} from './brands'
import {route as sourcesRoute} from './sources'
import {route as partnersRoute} from './partners'
import {route as userRoute} from './user'
import {route as usersRoute} from './users'
import {route as dashboardRoute} from './dashboard'
import {route as depositsRoute} from './deposits'
import {route as marketplaceRoute} from './marketplace'
import {route as statusesRoute} from './statuses'
import {route as testLeadIntegration} from './test-lead-integration'
import {route as reports} from './reports'
import {route as brand} from './brand'

export const routes = [
    mainRoute,
    leadsRoute,
    brandsRoute,
    sourcesRoute,
    partnersRoute,
    userRoute,
    usersRoute,
    dashboardRoute,
    depositsRoute,
    marketplaceRoute,
    statusesRoute,
    testLeadIntegration,
    reports,
    brand
]
