import BrandsPage from "@/pages/brands";

export const route = {
    path: '/brands',
    name: 'brandsPage',
    meta: {
        permission: 'brands'
    },
    component: BrandsPage,
};
