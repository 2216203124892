import {countriesList} from "@/shared/data/countriesList"
import {GeneralNotificationsModel} from '@/entities/GeneralNotifications'

/**
 * Copies the specified text to the clipboard.
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {Promise<void>} A Promise that resolves when the text has been successfully copied to the clipboard.
 */
export const copyToClipboard = (text) => {
    const generalNotificationsStore = GeneralNotificationsModel.useGeneralNotificationsStore()

    if (Array.isArray(text)) {
        let countries = ''
        text.forEach(el => {
            countries += countriesList()[el] + ', '
        })
        text = countries.slice(0, -2)
    }

    generalNotificationsStore.showSuccess({
        message: 'successfully copied'
    })
    return navigator.clipboard.writeText(text)
}
