<script setup>
import {defineProps} from 'vue'
import {timeFormat} from '@/shared/lib/utils/Date/format'

const props = defineProps({
    data: {
        type: Object
    },
    isFavorite: {
        type: Boolean,
        default: false
    }
})

import {SessionModel} from '@/entities/Session'
const sessionStore = SessionModel.useSessionStore()

</script>

<template>
    <div class="card-box" :class="{ 'is-favorite': isFavorite }">
        <div class="actions">
            <slot name="actions"></slot>
        </div>

        <div class="title-box">
            <div class="stats">
                <div class="stat">
                    <p>Rows total</p>
                    {{data.rowStats.totalRows}}
                </div>
                <div class="stat">
                    <p>Enabled rows</p>
                    {{data.rowStats.enableRows}}
                </div>
                <div class="stat">
                    <p>Disabled rows</p>
                    {{data.rowStats.disableRows}}
                </div>
            </div>
            <h3 class="name typo-heading-h3">{{ data.name }}</h3>
            <p class="id">Box ID: {{ data.numericId }}</p>
        </div>
        <div class="footer">
            <div class="item">
                <p>Created:</p>
                <p>{{ timeFormat(data.created, parseInt(sessionStore.offset !== undefined ? sessionStore.offset : new Date().getTimezoneOffset())) }}</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card-box {
    width: 320px;
    padding: 16px 8px;
    border-radius: var(--general-border-radius);
    background: var(--dark-bg);
    cursor: pointer;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    position: relative;
}

.card-box.is-favorite {
    border: 1px solid var(--purple);
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title-box,
.footer {
    padding: 0 12px;
}

.stats {
    margin: 16px 0 24px;
    width: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.stat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white-064);
    font-size: 12px;
}

.name {
    color: var(--light-purple);
    font-size: 18px;
    font-weight: 400;
    word-break: break-all;
    text-align: center;
}

.id {
    color: var(--white);
}

.footer {
    margin-top: 32px;
}

.footer .item p:first-child {
    color: var(--white-064);
    font-size: 12px;
}

.footer .item p:last-child {
    color: var(--white-086);
    font-size: 12px;
}
</style>
