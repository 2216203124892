import BrandPage from "@/pages/brand";

export const route = {
    path: '/brands/:id',
    name: 'brandPage',
    meta: {
        permission: 'brands',
    },
    component: BrandPage,
};
