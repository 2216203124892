import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/app/providers/router/routes'
import { AuthorizationModel } from '@/features/Authorization'
import { HeaderModel } from '@/entities/Header'
import {SessionModel} from '@/entities/Session'

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    const loggedIn = localStorage.getItem('token')
    const loggedInAsAffiliate = localStorage.getItem('affiliate_token')
    const authorizationStore = AuthorizationModel.useAuthorizationStore()
    const viewStore = HeaderModel.useHeaderStore()
    const sessionStore = SessionModel.useSessionStore()

    if ((loggedIn || loggedInAsAffiliate) && !authorizationStore.loginState) {
        try {
            const user = await authorizationStore.autoLogin()
            authorizationStore.loginState = true
            if (to.name === 'loginPage') {
                next({ name: viewStore.homeUrlName })
            } else {
                checkPermissions(to, user) ? next() : next({ name: viewStore.homeUrlName })
            }
        } catch (error) {
            next({ name: 'loginPage' })
        }
    } else if (requiresAuth(to) && !loggedIn) {
        next({ name: 'loginPage' })
    } else {
        sessionStore.setPageState(true)
        next()
    }
})

const requiresAuth = (route) => {
    return !route.meta.isPublic
}

const checkPermissions = (route, user) => {
    return user.permissions.includes(route.meta.permission) || route.meta.isPublic
}

export default router
